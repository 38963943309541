import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = {
    url: String
  }

  send(event) {
    if (typeof navigator === 'undefined') return
    const formData = new FormData()
    const csrfParam = document.querySelector("meta[name=csrf-param]").getAttribute("content")
    const csrfToken = document.querySelector("meta[name=csrf-token]").getAttribute("content")
    const { params } = event
    formData.append(csrfParam, csrfToken)
    for(var key in params ) {
      formData.append(key, params[key]);
    }
    navigator.sendBeacon(this.urlValue, formData)
  }

}