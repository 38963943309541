import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'upload'
  ]

  connect() {
    this.render(this.element)
  }

  change(event) {
    const { target: select } = event
    const option = select.options[select.selectedIndex]
    this.render(option)
  }

  render(option) {
    const designId = option.dataset.designId
    this.renderUpload(designId)
  }

  renderUpload(designId) {
    if (designId) {
      this.uploadTarget.setAttribute('hidden', true)
    } else {
      this.uploadTarget.removeAttribute('hidden')
    }
  }
}
