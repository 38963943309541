import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'menu',
    'backdrop',
    'qr',
    'share'
  ]

  static values = {
    share: Object
  }

  connect() {
    this.hide()
  }

  async show(event) {
    const target = event.params.target
    if (target == 'qr') {
      this._showBackdrop()
      this.menuTarget.classList.remove('translate-y-full')
    } else if (target == 'share') {
      await this._share()
    }
  }

  hide() {
    this.menuTarget.classList.add('translate-y-full')
    this._hideBackdrop()
  }

  _showBackdrop() {
    this.backdropTarget.classList.remove('hidden')
  }

  _hideBackdrop() {
    this.backdropTarget.classList.add('hidden')
  }

  async _share() {
    try {
      await navigator.share(this.shareValue)      
    } catch (err) {
    }
  }

}