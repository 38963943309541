import { Controller } from "@hotwired/stimulus"
import Cropper from 'cropperjs'

// Connects to data-controller="cropper"
export default class extends Controller {

  static values = {
    fieldName: String,
    aspectRatio: {
      type: Number,
      default: NaN
    },
    mimeType: {
      type: String,
      default: 'image/jpeg'
    },
    imageUrl: String,
    prompt: {
      type: String,
      default: 'Click to upload profile photo'
    }
  }

  static targets = [
    'existing',
    'existingImage',
    'choose',
    'input',
    'croppable',
    'output',
    'prompt'
  ]


  connect() {
    if (this.imageUrlValue) {
      this.existingImageTarget.src = this.imageUrlValue
      this.existingTarget.classList.remove('hidden')
    } else {
      this.chooseTarget.classList.remove('hidden')
    }
    this.promptTarget.innerText = this.promptValue
  }

  change(event) {
    this.delete = false
    // const name = event.target.files[0].name
    // const type = event.target.files[0].type
    // console.log(name, type)
    const blob = URL.createObjectURL(event.target.files[0])
    
    this.croppableTarget.src = blob
    this.cropper = new Cropper(this.croppableTarget, {
      aspectRatio: this.aspectRatioValue,
      viewMode: 3,
    })
    this.croppableTarget.classList.remove('hidden')
    this.chooseTarget.classList.add('hidden')
  }

  attach(event) {
    const body = event.detail.formSubmission.fetchRequest.body
    if (this.delete) {
      body.set(this.fieldNameValue, '')
    } else if (this.cropper) {
      const data = this.cropper.getCroppedCanvas().toDataURL(this.mimeTypeValue)
      const blob = this.dataURItoBlob(data)
      const file = new File([blob], 'attachment.jpg', { type: blob.type })
      body.set(this.fieldNameValue, file)
    }
  }


  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString })

  }

  edit() {
    this.delete = true
    this.existingTarget.classList.add('hidden')
    this.chooseTarget.classList.remove('hidden')
  }


}
