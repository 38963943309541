import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  
  static values = {
    kind: String,
    prefix: String,
    defaultName: String
  }

  static targets = [
    'value',
    'name'
  ]

  connect() {
    if (this.defaultNameValue) {
      this.nameTarget.value = this.defaultNameValue
    }
  }

  disconnect() {

  }

  onBlurValue(event) {
    switch(this.kindValue) {
      case 'facebook':
        this._handleFacebook(event)
        break
      case 'instagram':
        this._handleInstagram(event)
        break
      case 'tiktok':
        this._handleTiktok(event)
        break
      case 'website':
        this._handleWebsite(event)
        break
      case 'linkedin':
        this._handleLinkedin(event)
        break
      case 'messenger':
        this._handleMessenger(event)
        break
    }
  }

  _handleFacebook(event) {
    const value = event.target.value
    const regex = /((https?:\/\/)?(www\.)?facebook\.com\/)?(.*)/i
    const matches = (value.match(regex) || [])[4]
    if (matches) {
      event.target.value = this.prefixValue + matches
      if (this.nameTarget.value.length == 0) {
        this.nameTarget.value = matches
      }
    }
  }

  _handleInstagram(event) {
    const value = event.target.value
    const regex = /((https?:\/\/)?(www\.)?instagram\.com\/)?(.*)/i
    const matches = (value.match(regex) || [])[4]
    if (matches) {
      event.target.value = this.prefixValue + matches
      if (this.nameTarget.value.length == 0) {
        this.nameTarget.value = matches
      }
    }
  }

  _handleTiktok(event) {
    const value = event.target.value
    const regex = /((https?:\/\/)?(www\.)?tiktok\.com\/)?(@.*)/i
    const matches = (value.match(regex) || [])[4]
    if (matches) {
      event.target.value = this.prefixValue + matches
      if (this.nameTarget.value.length == 0) {
        this.nameTarget.value = matches
      }
    }
  }

  _handleWebsite(event) {
    const value = event.target.value
    const regex = /(https?:\/\/)?(.*)/i
    const matches = (value.match(regex) || [])[2]
    if (matches) {
      event.target.value = this.prefixValue + matches
    }
  }

  _handleLinkedin(event) {
    const value = event.target.value
    const regex = /((https?:\/\/)?(www\.)?linkedin\.com\/in\/)?(.*)/i
    const matches = (value.match(regex) || [])[4]
    if (matches) {
      event.target.value = this.prefixValue + matches
      if (this.nameTarget.value.length == 0) {
        this.nameTarget.value = matches
      }
    }
  }

  _handleMessenger(event) {
    const value = event.target.value
    const regex = /((https?:\/\/)?m\.me\/)?(.*)/i
    const matches = (value.match(regex) || [])[3]
    if (matches) {
      event.target.value = this.prefixValue + matches
      if (this.nameTarget.value.length == 0) {
        this.nameTarget.value = matches
      }
    }
  }

}