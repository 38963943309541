import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = {
    kind: String
  }
  
  static targets = [
    'calling',
    'multi'
  ]

  connect() {
    this.render(this.kindValue)
  }

  change(event) {
    const { target: select } = event
    const option = select.options[select.selectedIndex]
    this.render(option.value)
  }

  render(value) {
    if (value == 'calling') {
      this.renderCalling()
    } else if (value == 'multi') {
      this.renderMulti()
    }
  }

  renderCalling() {
    this.callingTarget.removeAttribute('hidden')
    this.multiTarget.setAttribute('hidden', true)
  }

  renderMulti() {
    this.multiTarget.removeAttribute('hidden')
    this.callingTarget.setAttribute('hidden', true)
  }

}
